import React from "react";
import {mainCarouselImagesByIndex, mainCarouselAltByIndex, mainCarouselLinksByIndex, mainCarouselImages} from "./carousel-content/MainCarousel";
import ThumbsCarousel from "./common/ThumbsCarousel";

const Home: React.FC = () => {
  const mainCarouselSlides = Array.from(Array(mainCarouselImages.length).keys());

  return (
    <div>  
      <ThumbsCarousel slides={mainCarouselSlides} imageByIndex={mainCarouselImagesByIndex} altByIndex={mainCarouselAltByIndex} linkByIndex={mainCarouselLinksByIndex} options={{ loop: true }} />
    </div>
  );
};

export default Home;
