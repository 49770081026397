import React from 'react';
import './static/App.css';

import Home from './Home';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <Home></Home>
        <p>
        © Wazuki Design
        </p>
      </header>
    </div>
  );
}

export default App;
