import suggoiImage from "../content/images/suggoi.png";
import darumaImage from "../content/images/daruma.png";
// import manekiImage from "../content/images/maneki-neko.png"; // "https://www.redbubble.com/shop/ap/153222493"

export const mainCarouselImages: string[] = [suggoiImage, darumaImage];
export const mainCarouselAlt: string[] = ["Suggoi", "Daruma"];
export const linkByIndex: string[] = ["https://www.redbubble.com/shop/ap/152602720", "https://www.redbubble.com/shop/ap/153053254"];

export const mainCarouselImagesByIndex = (index: number): string => mainCarouselImages[index % mainCarouselImages.length];
export const mainCarouselAltByIndex = (index: number): string => mainCarouselAlt[index % mainCarouselAlt.length];
export const mainCarouselLinksByIndex = (index: number): string => linkByIndex[index % linkByIndex.length];